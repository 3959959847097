import React from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import analyzeIcon from "../Assets/Images/analyze.png";
import alignIcon from "../Assets/Images/align.png";
import advanceIcon from "../Assets/Images/advance.png";
import Testimonial from "./Testimonial";

const InviteInfo = (props) => {
  const { id } = useParams();

  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite your team to take this assessment</h3>
        <p>Invite your team to take this assessment with you, and view individual and group scores to find out where you are aligned and where you need to align around your AI conversation.</p>

        <div className="invite-reasons">
          <div className="reason">
            <img src={analyzeIcon} alt="insights icon" />
            <br />
            <div className="height30"></div>
            <span>Analyze</span>
            <br />
            Understand different perspectives from your team
          </div>

          <div className="reason">
            <img src={alignIcon} alt="alignment icon" />
            <br />
            <div className="height30"></div>
            <span>Align</span>
            <br />
            Align around an action that serves your church best
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="direction icon" />
            <br />
            <div className="height30"></div>
            <span>Advance</span>
            <br />
            Get expert advice based on your team scores
          </div>
        </div>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY TEAM</Button>
          </Link>
        </div>

        {/* <Testimonial /> */}
      </div>

      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default InviteInfo;
